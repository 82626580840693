import React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import imgBackground from 'images/about/about-background.png';
import MainContent from 'components/MainContent/MainContent';
import { Helmet } from 'react-helmet';

const MainContentWrapper = styled('div')`
  background: url(${imgBackground}) no-repeat center;
  background-size: cover;
  color: white;
  padding: 9.5625rem 0.5rem 4.3125rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const FormContainer = styled(Container)`
  display: flex;
  justify-content: center;
`;

const PerformerChicagoPage = () => {
  return (
    <MainContent>
      <Helmet>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/performer-chicago/`} />
      </Helmet>
      <MainContentWrapper>
        <FormContainer>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src='https://docs.google.com/forms/d/e/1FAIpQLSev5DzDb3W9v6x6X1ZAj-B8f1BP-p87627QWawcCBI7LIOMNw/viewform?embedded=true'
            width='640'
            frameBorder='0'
            marginHeight={0}
            marginWidth={0}
            height='2363'
          />
        </FormContainer>
      </MainContentWrapper>
    </MainContent>
  );
};

export default PerformerChicagoPage;
